import { createRouter, createWebHistory } from "vue-router";

import store from "../store";
import LoginLayout from "../views/login/LoginLayout";
import Login from "../views/login/Login";
import Exception from "../views/exception/Exception";

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("../layouts/BaseLayout"),
    meta: {
      title: "首页",
      requireAuth: true,
      key: "index",
    },
    children: [
      {
        path: "/dashboard",
        component: () => import("../views/index/Dashboard"),
        meta: {
          title: "控制台",
          requireAuth: true,
          menuType: "menu",
          menuShow: 1,
          key: "dashboard",
          icon: "wapi-dashboard-fill",
        },
      },
      {
        path: "/content",
        component: () => import("../views/content/Index"),
        redirect: "/article",
        meta: {
          title: "内容",
          requireAuth: true,
          menuType: "menu",
          menuShow: 1,
          key: "content",
          icon: "wapi-detail-fill",
        },
        children: [
          {
            path: "/article",
            redirect: "/article/list",
            component: () => import("../views/content/Article"),
            meta: {
              title: "文章管理",
              requireAuth: true,
              menuType: "subMenu",
              menuShow: 1,
              key: "content-article",
            },
            children: [
              {
                path: "/article/list",
                component: () => import("../views/content/article/List"),
                meta: {
                  title: "文章列表",
                  requireAuth: true,
                  menuType: "subMenu",
                  menuShow: 1,
                  key: "content-article-list",
                },
              },
              {
                path: "/article/cate",
                component: () => import("../views/content/article/Cate"),
                meta: {
                  title: "文章分类",
                  requireAuth: true,
                  menuType: "subMenu",
                  menuShow: 1,
                  key: "content-article-cate",
                },
              },
              {
                path: "/article/add",
                component: () => import("../views/content/article/Add"),
                meta: {
                  title: "添加文章",
                  requireAuth: true,
                  menuType: "subMenu",
                  menuShow: 0,
                  key: "content-article-add",
                },
              },
              {
                path: "/article/edit",
                component: () => import("../views/content/article/Edit"),
                meta: {
                  title: "编辑文章",
                  requireAuth: true,
                  menuType: "subMenu",
                  menuShow: 0,
                  key: "content-article-edit",
                },
              },
            ],
          },
          {
            path: "/pages",
            redirect: "/pages/list",
            component: () => import("../views/content/Pages"),
            meta: {
              title: "页面管理",
              requireAuth: true,
              menuType: "subMenu",
              menuShow: 1,
              key: "content-pages",
            },
            children: [
              {
                path: "/pages/list",
                component: () => import("../views/content/pages/List"),
                meta: {
                  title: "页面列表",
                  requireAuth: true,
                  menuType: "subMenu",
                  menuShow: 1,
                  key: "content-pages-list",
                },
              },
            ],
          },
        ],
      },
      {
        path: "/file",
        component: () => import("../views/file/Index"),
        redirect: "/photo/list",
        meta: {
          title: "文件",
          requireAuth: true,
          menuType: "menu",
          menuShow: 1,
          key: "file",
          icon: "wapi-file-fill",
        },
        children: [
          {
            path: "/photo/list",
            component: () => import("../views/file/photo/List"),
            meta: {
              title: "图片列表",
              requireAuth: true,
              menuType: "subMenu",
              menuShow: 1,
              key: "file-photo-list",
            },
          },
          {
            path: "/video/list",
            component: () => import("../views/file/video/List"),
            meta: {
              title: "视频列表",
              requireAuth: true,
              menuType: "subMenu",
              menuShow: 1,
              key: "file-video-list",
            },
          },
        ],
      },
      {
        path: "/user",
        component: () => import("../views/user/Index"),
        redirect: "/user/list",
        meta: {
          title: "用户",
          requireAuth: true,
          menuType: "menu",
          menuShow: 1,
          key: "user",
          icon: "wapi-crown-fill",
        },
        children: [
          {
            path: "/user/list",
            component: () => import("../views/user/List"),
            meta: {
              title: "用户列表",
              requireAuth: true,
              menuType: "subMenu",
              menuShow: 1,
              key: "user-list",
            },
          },
        ],
      },
      {
        path: "/app",
        component: () => import("../views/app/Index"),
        redirect: "/app/list",
        meta: {
          title: "应用",
          requireAuth: true,
          menuType: "menu",
          menuShow: 1,
          key: "app",
          icon: "wapi-appstore-fill",
        },
        children: [
          {
            path: "/app/list",
            component: () => import("../views/app/List"),
            meta: {
              title: "应用列表",
              requireAuth: true,
              menuType: "subMenu",
              menuShow: 1,
              key: "app-list",
            },
          },
        ],
      },
      {
        path: "/set",
        component: () => import("../views/set/Index"),
        redirect: "/set/web",
        meta: {
          title: "设置",
          requireAuth: true,
          menuType: "menu",
          menuShow: 1,
          key: "set",
          icon: "wapi-setting-fill",
        },
        children: [
          {
            path: "/set/web",
            component: () => import("../views/set/Web"),
            meta: {
              title: "网站设置",
              requireAuth: true,
              menuType: "subMenu",
              menuShow: 1,
              key: "set-web",
            },
          },
          {
            path: "/set/nav",
            component: () => import("../views/set/Nav"),
            meta: {
              title: "导航设置",
              requireAuth: true,
              menuType: "subMenu",
              menuShow: 1,
              key: "set-nav",
            },
          },
          {
            path: "/set/link",
            component: () => import("../views/set/Link"),
            meta: {
              title: "友情链接",
              requireAuth: true,
              menuType: "subMenu",
              menuShow: 1,
              key: "set-link",
            },
          },
        ],
      },
      {
        path: "/system",
        component: () => import("../views/system/Index"),
        redirect: "/system/auth",
        meta: {
          title: "系统",
          requireAuth: true,
          key: "system",
          icon: "wapi-control-fill",
          menuType: "menu",
          menuShow: 0,
        },
        children: [
          {
            path: "/system/auth",
            component: () => import("../views/system/Auth"),
            meta: {
              title: "授权",
              requireAuth: true,
              menuType: "subMenu",
              menuShow: 1,
              key: "system-auth",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    component: LoginLayout,
    children: [
      {
        path: "/login",
        component: Login,
        meta: {
          title: "登陆",
          requireAuth: false,
          menu: "login",
        },
      },
    ],
  },
  {
    path: "/exception",
    component: Exception,
    meta: {
      title: "异常信息",
      requireAuth: false,
      menu: "msg",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  if (to.matched.some((m) => m.meta.requireAuth)) {
    // 对路由进行验证
    if (store.state.authorization) {
      // 已经登陆
      next(); // 正常跳转到你设置好的页面
    } else {
      // 未登录则跳转到登陆界面
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    }
  } else {
    //如果是登陆重定向，带上query
    if (from.path === "/login" && JSON.stringify(from.query) !== "{}") {
      to.query = from.query;
    }
    next();
  }
});

export default router;
