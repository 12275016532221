import tool from "../utils/tool";

export default {
  adminInfo: tool.isJsonString(localStorage.getItem("adminInfo"))
    ? JSON.parse(localStorage.getItem("adminInfo"))
    : "",
  authorization: localStorage.getItem("authorization")
    ? localStorage.getItem("authorization")
    : "",
};
