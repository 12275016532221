export default {
  login(state, data) {
    state.adminInfo = data;
    localStorage.setItem("adminInfo", JSON.stringify(data));
  },
  changeAuthorization(state, authorization) {
    state.authorization = authorization;
    localStorage.setItem("authorization", authorization);
  },
};
