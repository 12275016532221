<template>
  <div id="container">
    <a-row>
      <a-col :xs="0" :sm="0" :md="0" :lg="8" :xl="10">
        <div class="page-left">
          <img
            src="https://oss.pigwan.net/wapi/images/static/login-bg.png"
            style="width: 100%; height: 100%; object-fit: cover"
            alt=""
          />
        </div>
      </a-col>
      <a-col :xs="24" :sm="24" :md="24" :lg="16" :xl="14">
        <div class="page-right">
          <div class="main">
            <div class="title" style="margin-bottom: 30px">
              <span style="font-size: 36px">欢迎登陆</span
              ><span
                style="font-weight: 300; margin-left: 16px; font-size: 18px"
                >网站管理系统</span
              >
            </div>
            <router-view></router-view>
          </div>
          <div class="footer">
            <div class="links">
              <a href="#">帮助</a>
              <a href="#">隐私</a>
              <a href="#">条款</a>
            </div>
            <div class="copyright">
              Copyright © 2014-2021
              <a class="p-link" target="_blank" href="https://pigwan.me"
                >🎉 version 1.0</a
              >

              <a class="p-link" target="_blank" href="https://pigwan.me"
                >🛠️ Powered by Pigwan</a
              >
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import tool from "../../utils/tool";
import { Col, Row } from "ant-design-vue/es";

export default {
  name: "LoginLayout",
  components: {
    ACol: Col,
    ARow: Row,
  },
  data() {
    return {
      width: "40%",
    };
  },
  created() {
    tool.logout();
  },
  methods: {},
};
</script>

<style scoped>
#container {
  width: 100%;
  min-height: 100%;
}

.page-left,
.page-right {
  height: 100vh;
}

.main {
  width: 448px;
  min-width: 340px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  top: 20vh;
}

.footer {
  text-align: center;
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.links {
  margin-bottom: 8px;
  font-size: 14px;
}

.links a:not(:last-child) {
  margin-right: 40px;
}

.links a {
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
  text-decoration: none;
}

.copyright {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
</style>
