import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "ant-design-vue/dist/antd.css";
import "./reset.css";
import axios from "axios";
import eventBus from "vue3-eventbus";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(eventBus);

app.config.productionTip = false;
app.config.globalProperties.$axios = axios;
axios.defaults.baseURL = "/api";
app.mount("#app");
