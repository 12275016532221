<template>
  <router-view />
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},

  methods: {},
};
</script>

<style lang="scss">
#app {
  height: 100vh;
  min-width: 1336px;
  background: #f0f2f5;
}
</style>
