<template>
  <a-form id="fromLogin" :model="formState" @finish="fromFinish">
    <a-form-item name="username">
      <a-input
        v-model:value="formState.username"
        size="large"
        placeholder="请输入用户名/手机号"
      >
        <template #prefix>
          <user-outlined
            style="color: #1890ff; margin-right: 4px"
            type="user"
          />
        </template>
      </a-input>
    </a-form-item>
    <a-form-item name="password">
      <a-input-password
        v-model:value="formState.password"
        size="large"
        placeholder="请输入密码"
      >
        <template #prefix>
          <lock-outlined
            style="color: #1890ff; margin-right: 4px"
            type="password"
          />
        </template>
      </a-input-password>
    </a-form-item>
    <a-form-item>
      <a-button
        type="primary"
        size="large"
        html-type="submit"
        block
        :disabled="disableLoginBtn"
        :loading="loadingLoginBtn"
        >登陆
      </a-button>
    </a-form-item>
  </a-form>
  <div class="user-login-other">
    <router-link to="#">忘记密码</router-link>
  </div>
</template>
<script>
import {
  Form,
  FormItem,
  Input,
  InputPassword,
  Button,
  message,
} from "ant-design-vue/es";
import { UserOutlined, LockOutlined } from "@ant-design/icons-vue";

import router from "../../router";
import tool from "../../utils/tool";
import login from "../../api/login";
import store from "../../store";

export default {
  name: "Login",
  data() {
    return {
      formState: {
        username: "",
        password: "",
        phone: "",
        phone_code: "",
      },
      disableSmsBtn: false,
      loadingSmsBtn: false,
      disableLoginBtn: false,
      loadingLoginBtn: false,
      tips: "获取验证码",
    };
  },
  components: {
    UserOutlined,
    LockOutlined,
    AForm: Form,
    AFormItem: FormItem,
    AInput: Input,
    AInputPassword: InputPassword,
    AButton: Button,
  },
  created() {},
  methods: {
    //获取验证码
    getPhoneCode() {
      let that = this;
      if (that.disableSmsBtn) {
        return false;
      }

      that.disableSmsBtn = true;
      that.loadingSmsBtn = true;

      if (!tool.isPhoneNumber(that.formState.phone)) {
        //检查不通过
        message.warning("请输入正确的手机号码");
        return false;
      }
    },

    fromFinish(field) {
      let that = this;

      //判断用户名，只能数字、字母、下划线
      if (!tool.isValid(field.username)) {
        message.warning("用户名只能使用数字、字母、下划线");
        return false;
      }

      if (field.username.length < 6 || field.username.length > 20) {
        message.warning("用户名长度为6-20个字符");
        return false;
      }

      //判断密码长度
      if (field.password.length < 6 || field.password.length > 20) {
        message.warning("密码名长度为6-20个字符");
        return false;
      }

      if (that.disableLoginBtn) {
        return false;
      }

      that.disableLoginBtn = true;
      that.loadingLoginBtn = true;
      message.info("登陆中...");

      login
        .login(field)
        .then((res) => {
          if (res.code > 0) {
            message.destroy();
            message.warning(res.msg);
            that.disableLoginBtn = false;
            that.loadingLoginBtn = false;
          } else {
            message.destroy();
            message.success(res.msg);
            //登陆成功，保存登陆信息

            console.log(res.data);

            store.commit("login", res.data);

            // 获取之前访问的URL
            let url = this.$route.query.redirect;
            if (this.$route.query.redirect === undefined) {
              url = "/";
            }

            //跳转
            router.push({
              path: url,
            });
          }
        })
        .catch((e) => {
          let error = JSON.parse(JSON.stringify(e));
          console.log(error);
          message.destroy();
          message.error(
            "出现错误，请联系管理员！错误代码：" +
              error.status +
              ",错误信息：" +
              error.message
          );
          that.disableLoginBtn = false;
          that.loadingLoginBtn = false;
        });
    },
  },
};
</script>

<style scoped>
.user-login-other {
  margin-top: 24px;
  line-height: 22px;
  text-align: left;
}
</style>
